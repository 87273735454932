.upload-container {
    position: relative;
    display: inline-block;
  }
  
  .upload-image {
    width: 100%;
    height: auto;
  }
  
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    overflow: hidden; /* Ensure stars don't overflow the container */
  }
  
  .star {
    position: absolute;
    width: 10px; /* Adjust the size of the stars */
    height: 10px;
    background-image: url('../images/star.png'); /* Path to your star image */
    background-size: cover;
    animation: star-animation 3s infinite;
  }
  
  @keyframes star-animation {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 0;
      transform: scale(1.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  