.about-root {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.hide-scrollbar {
    overflow: hidden; /* 隐藏滚动条 */
}

.grid {
  display: grid;
  gap: 1rem; /* Adjust the gap between images */
}

img {
  border-radius: 8px; /* Optional: adds rounded corners to images */
  transition: transform 0.2s; /* Optional: adds a hover effect */
}

img:hover {
  transform: scale(1.05); /* Optional: scales image on hover */
}