.bottom-toolbar {
    position: fixed;  /* Changed from absolute to fixed */
    right: 20px;     /* Added to position on right side */
    top: 50%;        /* Center vertically */
    transform: translateY(-50%);  /* Center vertically */
    z-index: 1;
    background: #fff;
    padding: 20px;
    padding-bottom: 32px;
    border-radius: 20px;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.05);
    width: 150px;
    height: 80%;
    overflow-x: hidden;
}
  
  .color-picker {
    /* display: flex; */
    flex-direction: column;  /* Changed to column for vertical layout */
    overflow-y: auto;       /* Changed to vertical scroll */
    padding: 15px 0;
    gap: 10px;             /* Reduced gap for vertical layout */
    
    &::-webkit-scrollbar {
      width: 6px;          /* Added width for vertical scrollbar */
    }
    
    &::-webkit-scrollbar-thumb {
      background: #ddd;
      border-radius: 3px;
    }
  }
  
  .color-box {
    width: 80px;          /* Full width */
    height: 80px;         /* Reduced height for vertical layout */
    border-radius: 8px;
    border: 2px solid #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    cursor: pointer;
    transition: transform 0.2s ease;
    
    &.active {
      border-color: transparent;
      transform: scale(1.02);
    }
    
    &:hover {
      transform: scale(1.02);
    }
    
    &:active {
      transform: scale(0.98);
    }
  }

.download-buttons {
    position: fixed;  /* Changed from absolute to fixed */
    right: 20px;     /* Added to position on right side */
    top: 50%;        /* Center vertically */
    transform: translateY(-50%);  /* Center vertically */
    z-index: 1;
    background: transparent;
    padding: 20px;
    padding-bottom: 32px;
    border-radius: 20px;
    margin-right: 200px;
    /* box-shadow: 0 -2px 10px rgba(0,0,0,0.05); */
    width: 150px;
    height: 80%;
    overflow-x: hidden;
    align-content: center;
}

.hex-input-container {
    display: flex;
    align-items: center;
    background: #f5f5f5;
    border-radius: 6px;
    padding: 8px 12px;
    margin-bottom: 64px;
  }
  
  .hex-label {
    color: #666;
    font-size: 14px;
    margin-right: 4px;
  }
  
  .hex-input {
    background: transparent;
    border: none;
    outline: none;
    width: 80px;
    font-family: monospace;
    font-size: 14px;
    color: #333;
  }
  
  .hex-input::placeholder {
    color: #999;
  }