@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f0f0f0; /* Light gray background color */
  margin: 0;
  height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.app-bar {
  background-color: #f0f0f0; /* Light gray background color */
  width: 100%;
  border-bottom: 0.1px solid #e0e0e0; /* Light gray border line */
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); /* Light shadow */
}

.app-bar-title {
  /* flex-grow: 1; */
  text-align: left;
  color: rgb(69, 69, 69); /* Dark gray text color */
}

.app-container {
  /* background-color: #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
}

.content-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh + 64px); /* Adjust height to account for AppBar */
}

.image-box {
  /* border: 2px dashed #ccc; */
  /* border: 2px #ccc; */
  overflow: hidden;
}

img {
  border-radius: 0;
}

.show_buttons {
  display: flex;
  align-items: center; /* 这会垂直居中子元素 */
}
